import { NextSeo, BreadcrumbJsonLd } from 'next-seo';
import { decodeHTMLEntities } from '@/utils/decodeEntities';
import useBreadcrumbInfo from '@/hooks/useBreadcrumbInfo';
import { useRouter } from 'next/router';

const PageSEO = ({ data, title, type }) => {
    const { categories, tags, title: dataTitle, content, link, featuredImage, excerpt, date, modified, name, uri } = data || {};
    const wp = process.env.NEXT_PUBLIC_WP;
    const production = wp.includes('https://v2-live-wp.jewsforjesus.org') || wp.includes('https://jfj-live.stellate.sh');
    // const canonicalValue = production ? link?.replace('v2-live-wp.', '') || undefined : link || undefined;

    const canonicalValue = production ? link?.replace('v2-live-wp.', '')?.replace(/\/$/, '') || undefined : link?.replace(/\/$/, '') || undefined;
    // console.log(canonicalValue);

    const tagNodes = tags?.nodes;
    const newsletterCategoryPage = uri === '/publications/newsletter';
    // no-search tag means the page/post won't appear on Google or our site search.
    const noSearchTag = tagNodes?.find(node => node.name === 'no-search');
    const breadcrumbInfo = useBreadcrumbInfo(categories) || [];

    const router = useRouter();
    const { asPath } = router;

    const homePage = asPath === '/' && ['home'];
    const pathArray = homePage || asPath.split('/').filter(Boolean);

    const pathArrayFilteredUppercase = pathArray.map((item, index) => {
        const itemObj = {};
        itemObj.name = item.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
        if (item === 'home') itemObj.uri = '/';
        else if (index === 0) itemObj.uri = '/' + item;
        else itemObj.uri = '/' + pathArray.slice(0, index + 1).join('/');
        return itemObj;
    });

    const noIndexable = !process.env.NEXT_PUBLIC_WP.includes('v2-live') ? true : false;
    const dontIndexOrFollow = noSearchTag || type === 'GivePage' || noIndexable || newsletterCategoryPage;

    let siteName = 'Jews for Jesus';
    const categoryTitle = type === 'category' && name && `${name} Archives - Jews for Jesus`;
    const normalTitle = `${title || dataTitle} - Jews for Jesus`;

    const removeHTMLAndNewlines = str => {
        // Remove HTML tags
        const withoutTags = str.replace(/<[^>]+>/g, '');

        // Remove newline characters
        const withoutNewlines = withoutTags.replace(/\n/g, '');

        return withoutNewlines;
    };

    const description = removeHTMLAndNewlines(decodeHTMLEntities(excerpt || (content && `${content?.substring(0, 151)}...`)) || '');

    return (
        <>
            <NextSeo
                title={categoryTitle || decodeHTMLEntities(normalTitle || null) || undefined}
                noindex={dontIndexOrFollow}
                nofollow={dontIndexOrFollow}
                description={description}
                canonical={canonicalValue || undefined}
                openGraph={{
                    type: type || undefined,
                    title: categoryTitle || decodeHTMLEntities(normalTitle || null) || undefined,
                    description: description,
                    ...(featuredImage && {
                        images: [
                            {
                                url: featuredImage?.node?.sourceUrl,
                                width: featuredImage?.node?.mediaDetails?.width || undefined,
                                height: featuredImage?.node?.mediaDetails?.height || undefined,
                                alt: decodeHTMLEntities(featuredImage?.node?.altText || null) || undefined
                            }
                        ]
                    }),
                    siteName: siteName || undefined,
                    article: {
                        modifiedTime: modified || undefined,
                        publishedTime: date || undefined
                    }
                }}
            />
            <BreadcrumbJsonLd
                itemListElements={(pathArrayFilteredUppercase || [])
                    .filter(i => i?.name && i?.uri)
                    .map((breadcumb, i) => ({
                        position: i + 1,
                        name: breadcumb.name,
                        item: breadcumb.uri
                    }))}
            />
        </>
    );
};
export default PageSEO;
